import React, { useState, useEffect, useContext } from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import PrivateRoute from "./components/Routes/PrivateRoute";
import Category from "./pages/Category";
import Dashboard from "./pages/Dashboard";
import Documents from "./pages/Documents";
import Login from "./pages/Login";
import Users from "./pages/Users";
import Recents from "./pages/Recents";
import Starred from "./pages/Starred";
import { AuthContext } from "../src/context/Auth/AuthContext";
import TrashDocuments from "./pages/TrashDocuments";
import TrashUsers from "./pages/TrashUsers";
import TrashCategory from "./pages/TrashCategory";
import AllCaseList from "./pages/AllCaseList";
import EmailCaseList from "./pages/EmailCaseList";
import FaxCaseList from "./pages/FaxCaseList";
import ScanCaseList from "./pages/ScanCaseList";
import Callback from "./pages/Callback";
import AssignedCaseList from "./pages/AssignedCaseList";
import "./styles/App.css";
import UnassignedCaseList from "./pages/UnassignedCaseList";
import Reports from "./pages/Reports";
import ProcessedCaseList from "./pages/ProcessedCaseList";
import MyAssignedCaseList from "./pages/MyAssignedCaseList";
import ClientUnknownCaseList from "./pages/ClientUnknownCaseList";
import ServiceCaseList from "./pages/ServiceCaseList";
import Clients from "./pages/Clients";
import FieldSettings from "./pages/FieldSettings";
import DocumentRequestSettings from "./pages/DocumentRequestSettings";
import ContractTemplates from "./pages/ContractTemplates";
import Contracts from "./pages/Contracts";
import Requests from "./pages/Requests";
import RequestedContracts from "./pages/RequestedContracts";
import SignedContracts from "./pages/SignedContracts";

function App() {
	const { is_admin, token } = useContext(AuthContext);

	return (
		<div className="app">
			<ToastContainer />
			<Router>
				<Routes>
					<Route path="/" element={<Navigate to="/login" />} />
					<Route exact path="/login" element={<Login />} />
					<Route exact path="/callback" element={<Callback />} />
					<Route
						path="/dashboard"
						element={
							<PrivateRoute>
								<Dashboard />
							</PrivateRoute>
						}
					/>
					{is_admin === "1" ? (
						<Route
							path="/reports"
							element={
								<PrivateRoute>
									<Reports />
								</PrivateRoute>
							}
						/>
					) : null}
					{is_admin === "0" ? (
						<>
							<Route
								path="/documents"
								element={
									<PrivateRoute>
										<Documents />
									</PrivateRoute>
								}
							/>
							<Route
								path="/email-case-list"
								element={
									<PrivateRoute>
										<EmailCaseList />
									</PrivateRoute>
								}
							/>
							<Route
								path="/fax-case-list"
								element={
									<PrivateRoute>
										<FaxCaseList />
									</PrivateRoute>
								}
							/>
							<Route
								path="/scan-case-list"
								element={
									<PrivateRoute>
										<ScanCaseList />
									</PrivateRoute>
								}
							/>
						</>
					) : (
						<>
							<Route
								path="/documents"
								element={
									<PrivateRoute>
										<Documents />
									</PrivateRoute>
								}
							/>
							<Route
								path="/clients"
								element={
									<PrivateRoute>
										<Clients />
									</PrivateRoute>
								}
							/>
							<Route
								path="/clients/:cid"
								element={
									<PrivateRoute>
										<Clients />
									</PrivateRoute>
								}
							/>
							<Route
								path="/clients/:cid/:bid"
								element={
									<PrivateRoute>
										<Clients />
									</PrivateRoute>
								}
							/>
							<Route
								path="/clients/:cid/uploads"
								element={
									<PrivateRoute>
										<Clients />
									</PrivateRoute>
								}
							/>
							<Route
								path="/recent-documents"
								element={
									<PrivateRoute>
										<Recents />
									</PrivateRoute>
								}
							/>
							<Route
								path="/starred-documents"
								element={
									<PrivateRoute>
										<Starred />
									</PrivateRoute>
								}
							/>
							<Route
								path="/requests"
								element={
									<PrivateRoute>
										<Requests />
									</PrivateRoute>
								}
							/>
							<Route
								path="/email-case-list"
								element={
									<PrivateRoute>
										<EmailCaseList />
									</PrivateRoute>
								}
							/>
							<Route
								path="/fax-case-list"
								element={
									<PrivateRoute>
										<FaxCaseList />
									</PrivateRoute>
								}
							/>
							<Route
								path="/scan-case-list"
								element={
									<PrivateRoute>
										<ScanCaseList />
									</PrivateRoute>
								}
							/>
						</>
					)}
					{is_admin === "1" ? (
						<>
							<Route
								path="/all-assigned-case-list"
								element={
									<PrivateRoute>
										<AllCaseList />
									</PrivateRoute>
								}
							/>
							<Route
								path="/client-unknown-category"
								element={
									<PrivateRoute>
										<ClientUnknownCaseList />
									</PrivateRoute>
								}
							/>
						</>
					) : null}
					<Route
						path="/my-processed-case-list"
						element={
							<PrivateRoute>
								<ProcessedCaseList />
							</PrivateRoute>
						}
					/>
					<Route
						path="/my-assigned-case-list"
						element={
							<PrivateRoute>
								<MyAssignedCaseList />
							</PrivateRoute>
						}
					/>
					<Route
						path="/unassigned-case-list"
						element={
							<PrivateRoute>
								<UnassignedCaseList />
							</PrivateRoute>
						}
					/>
					<Route
						path="/assigned-case-list"
						element={
							<PrivateRoute>
								<AssignedCaseList />
							</PrivateRoute>
						}
					/>

					<Route
						path="/email-case-list"
						element={
							<PrivateRoute>
								<EmailCaseList />
							</PrivateRoute>
						}
					/>
					<Route
						path="/fax-case-list"
						element={
							<PrivateRoute>
								<FaxCaseList />
							</PrivateRoute>
						}
					/>
					<Route
						path="/scan-case-list"
						element={
							<PrivateRoute>
								<ScanCaseList />
							</PrivateRoute>
						}
					/>
					<Route
						path="/service-case-list"
						element={
							<PrivateRoute>
								<ServiceCaseList />
							</PrivateRoute>
						}
					/>
					{is_admin === "0" ? null : (
						<>
							<Route
								path="/users"
								element={
									<PrivateRoute>
										<Users />
									</PrivateRoute>
								}
							/>
							<Route
								path="/contract-templates"
								element={
									<PrivateRoute>
										<ContractTemplates />
									</PrivateRoute>
								}
							/>
							<Route
								path="/contracts"
								element={
									<PrivateRoute>
										<Contracts />
									</PrivateRoute>
								}
							/>
							<Route
								path="/requested-contracts"
								element={
									<PrivateRoute>
										<RequestedContracts />
									</PrivateRoute>
								}
							/>
							<Route
								path="/signed-contracts"
								element={
									<PrivateRoute>
										<SignedContracts />
									</PrivateRoute>
								}
							/>
							<Route
								path="/document-request-settings"
								element={
									<PrivateRoute>
										<DocumentRequestSettings />
									</PrivateRoute>
								}
							/>
							<Route
								path="/field-settings"
								element={
									<PrivateRoute>
										<FieldSettings />
									</PrivateRoute>
								}
							/>
							<Route
								path="/category"
								element={
									<PrivateRoute>
										<Category />
									</PrivateRoute>
								}
							/>
						</>
					)}
					{is_admin === "0" ? null : (
						<Route
							path="/trash-users"
							element={
								<PrivateRoute>
									<TrashUsers />
								</PrivateRoute>
							}
						/>
					)}
					<Route
						path="/trash-documents"
						element={
							<PrivateRoute>
								<TrashDocuments />
							</PrivateRoute>
						}
					/>
					{is_admin === "0" ? null : (
						<Route
							path="/trash-category"
							element={
								<PrivateRoute>
									<TrashCategory />
								</PrivateRoute>
							}
						/>
					)}
				</Routes>
			</Router>
		</div>
	);
}

export default App;
