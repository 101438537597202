const getAllTaskTypeList = async ({ token }) => {
    try {
        const response = await fetch(
            `https://clguat.nablasol.net/rest/v11_20/get-task-type-list?category=Immigration`,
            {
                method: "POST",
                // headers: {
                //     Accept: "application/json",
                //     Authorization: `Bearer ${token}`,
                // },
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};

export default getAllTaskTypeList;
