import React, { useState, useContext, useEffect } from "react";
import { Controller } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../material-ui/InputUI";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const EditDocumentForm = ({
  register,
  control,
  setValue,
  watch,
  errors,
  tableValues,
  clientTypeOptions,
}) => {
  const selectedTabType = watch("fieldsTabsType");
  const statusOptions = [
    {
      name: "Active",
      value: "active",
    },
    {
      name: "Inactive",
      value: "inactive",
    },
  ];

  const typeOptions = [
    {
      name: "Client Type",
      value: "client_type",
    },
    {
      name: "Client Sub Type",
      value: "client_subtype",
    },
    {
      name: "Document Type",
      value: "document_type",
    },
    {
      name: "Document Sub Type",
      value: "document_subtype",
    },
  ];

  const [showCategoryField, setShowCategoryField] = useState(
    tableValues.fields_tab_type.toLowerCase() === "client_subtype"
  );

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    ignoreCase: true, // Ignore case sensitivity
    stringify: (option) => option.label,
  });

  useEffect(() => {
    if (selectedTabType !== undefined && selectedTabType === "client_subtype") {
      setShowCategoryField(true);
    } else {
      setShowCategoryField(false);
    }
  }, [selectedTabType]);

  return (
    <Grid className="">
      <div className="edit-document-form grid grid-cols-1 gap-3 sm:grid-cols-2">
        <div className="field_wrapper">
          <div className="field">
            <div className="input-field">
              <Controller
                name="fieldName"
                defaultValue={tableValues.fields_name}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputUI
                    name="fieldName"
                    label="Field Name"
                    type="text"
                    value={value ? value : ""}
                    setValue={onChange}
                    variant="outlined"
                    register={register}
                  />
                )}
              />
            </div>
            <span className="error-message">
              {errors["fieldName"]?.message}
            </span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <div className="input-field">
              <Controller
                name="description"
                defaultValue={tableValues.fields_description || ""}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputUI
                    name="description"
                    label="Description"
                    type="text"
                    value={value ? value : ""}
                    setValue={onChange}
                    variant="outlined"
                    register={register}
                  />
                )}
              />
            </div>
            <span className="error-message">
              {errors["description"]?.message}
            </span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <FormControl className="" fullWidth>
              <InputLabel className="edit-doc-select-label" id="select-label">
                Field type
              </InputLabel>
              <Controller
                control={control}
                name="fieldsTabsType"
                defaultValue={tableValues.fields_tab_type}
                render={({ field: { onChange, value } }) => (
                  <Select
                    name="fieldsTabsType"
                    labelId="select-label"
                    variant="outlined"
                    id="simple-select"
                    value={value}
                    onChange={onChange}
                    label="Field type"
                    disabled={true}
                    style={{ marginBottom: "25px" }}
                    {...register("fieldsTabsType")}
                  >
                    {typeOptions?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <span className="error-message">{errors["status"]?.message}</span>
          </div>
        </div>
        {showCategoryField && (
          <div className="field_wrapper">
            <div className="field">
              <FormControl className="" fullWidth>
                <Controller
                  control={control}
                  name="addClientType"
                  defaultValue={tableValues.category_id}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      options={clientTypeOptions}
                      filterOptions={filterOptions}
                      getOptionLabel={(option) => option.label || ""}
                      onChange={(e, value) => {
                        onChange(value ? value.typeId : "");
                      }}
                      value={
                        clientTypeOptions?.find(
                          (option) => option.typeId === value
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Category"
                          variant="outlined"
                          error={!!errors["addClientType"]}
                          helperText={errors["addClientType"]?.message}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>
        )}

        <div className="field_wrapper">
          <div className="field">
            <FormControl className="" fullWidth>
              <InputLabel className="edit-doc-select-label" id="select-label">
                Status
              </InputLabel>
              <Controller
                control={control}
                name="status"
                defaultValue={tableValues.status.toLowerCase()}
                render={({ field: { onChange, value } }) => (
                  <Select
                    name="status"
                    labelId="select-label"
                    variant="outlined"
                    id="simple-select"
                    value={value}
                    onChange={onChange}
                    label="Status"
                    {...register("status")}
                  >
                    {statusOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <span className="error-message">{errors["status"]?.message}</span>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default EditDocumentForm;
