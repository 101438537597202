import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AiFillCloseCircle } from "react-icons/ai";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../material-ui/InputUI";
import ButtonUI from "../material-ui/ButtonUI";
import { AuthContext } from "../../context/Auth/AuthContext";
import addClientFieldSettings from "../../api/addClientFieldSettings";
import getAllTypeList from "../../api/getAllFieldTypeList";
import addFieldSettingsImg from "../../images/add-user.png";
import "../../styles/AddDocument.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";

const addClientFieldSettingsFormSchema = yup.object().shape({
  fieldNameClient: yup.string().required("Client Category is required field"),
  descriptionClient: yup.string().required("Description is required field"),
  statusClient: yup.string().required("Status is required field"),
});

const addClientSubFieldSettingsFormSchema = yup.object().shape({
  fieldNameClientSub: yup
    .string()
    .required("Client Sub Category is required field"),
  descriptionClientSub: yup.string().required("Description is required field"),
  //addClientType: yup.string().required(" required field"),
  statusClientSub: yup.string().required("Status is required field"),
});

const addDocumentFieldSettingsFormSchema = yup.object().shape({
  fieldNameDocument: yup
    .string()
    .required("Document Category is required field"),
  descriptionDocument: yup.string().required("Description is required field"),
  statusDocument: yup.string().required("Status is required field"),
});

const addDocumentSubFieldSettingsFormSchema = yup.object().shape({
  fieldNameDocumentSub: yup
    .string()
    .required("Document Sub Category is required field"),
  descriptionDocumentSub: yup
    .string()
    .required("Description is required field"),
  statusDocumentSub: yup.string().required("Status is required field"),
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AddFieldSettings = ({
  onAddFieldSettingsButtonClick,
  setFieldSettingsAddedToTrue,
  clientTypeOptions,
}) => {
  const [loading, setLoading] = useState(false);
  const { token, user_id, is_admin } = useContext(AuthContext);
  const [value, setValue] = React.useState(0);
  // const [typeOptions, setTypeOptions] = useState([]);

  // const type = "client_type";
  // const clientTypeLoad = async () => {
  //   const data = await getAllTypeList({ token, type });
  //   if (data.code == 200) {
  //     const filteredTypeList =
  //       data?.data !== null
  //         ? data?.data.map((type) => {
  //           return {
  //             label: type.fields_name,
  //             typeId: type.field_setting_id,
  //           };
  //         })
  //         : [];
  //     setTypeOptions(filteredTypeList);
  //   } else {
  //     setTypeOptions([]);
  //   }
  // };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    register: register,
    handleSubmit: handleSubmit,
    control: control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addClientFieldSettingsFormSchema),
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    ignoreCase: true, // Ignore case sensitivity
    stringify: (option) => option.label,
  });

  const {
    register: registerClientSub,
    handleSubmit: handleSubmitClientSub,
    control: controlClientSub,
    formState: { errors: errorsClientSub },
  } = useForm({
    resolver: yupResolver(addClientSubFieldSettingsFormSchema),
  });

  const {
    register: registerDoc,
    handleSubmit: handleSubmitDoc,
    control: controlDoc,
    formState: { errors: errorsDoc },
  } = useForm({
    resolver: yupResolver(addDocumentFieldSettingsFormSchema),
  });

  const {
    register: registerDocSub,
    handleSubmit: handleSubmitDocSub,
    control: controlDocSub,
    formState: { errors: errorsDocSub },
  } = useForm({
    resolver: yupResolver(addDocumentSubFieldSettingsFormSchema),
  });

  const statusOptions = [
    {
      name: "Active",
      value: "active",
    },
    {
      name: "Inactive",
      value: "inactive",
    },
  ];

  const onClientAddFieldSettingsFormSubmit = async (
    addClientFieldSettingsFormData
  ) => {
    setLoading(true);

    const addClientFieldSettingsFormResponse = await addClientFieldSettings({
      token: token,
      userId: user_id,
      fields_name: addClientFieldSettingsFormData.fieldNameClient,
      fields_description: addClientFieldSettingsFormData.descriptionClient,
      fields_tab_type: "client_type",
      status: addClientFieldSettingsFormData.statusClient,
      is_admin: is_admin,
    });

    if (addClientFieldSettingsFormResponse?.code === 200) {
      onAddFieldSettingsButtonClick();
      setFieldSettingsAddedToTrue();
      toast.success("Client Category is added successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Error while adding Client Category", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoading(false);
  };

  const onClientSubAddFieldSettingsFormSubmit = async (
    addClientSubFieldSettingsFormData
  ) => {
    setLoading(true);

    const addClientSubFieldSettingsFormResponse = await addClientFieldSettings({
      token: token,
      userId: user_id,
      category_id: addClientSubFieldSettingsFormData.addClientType,
      fields_name: addClientSubFieldSettingsFormData.fieldNameClientSub,
      fields_description:
        addClientSubFieldSettingsFormData.descriptionClientSub,
      fields_tab_type: "client_subtype",
      status: addClientSubFieldSettingsFormData.statusClientSub,
      is_admin: is_admin,
    });

    if (addClientSubFieldSettingsFormResponse?.code === 200) {
      setLoading(false);
      onAddFieldSettingsButtonClick();
      setFieldSettingsAddedToTrue();
      toast.success("Client Sub Category is added successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (addClientSubFieldSettingsFormResponse?.code === 401) {
      setLoading(false);
      toast.error("Error while adding Client Sub Category", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const onDocumentAddFieldSettingsFormSubmit = async (
    addDocumentFieldSettingsFormData
  ) => {
    setLoading(true);

    const addDocumentFieldSettingsFormResponse = await addClientFieldSettings({
      token: token,
      userId: user_id,
      fields_name: addDocumentFieldSettingsFormData.fieldNameDocument,
      fields_description: addDocumentFieldSettingsFormData.descriptionDocument,
      fields_tab_type: "document_type",
      status: addDocumentFieldSettingsFormData.statusDocument,
      is_admin: is_admin,
    });

    if (addDocumentFieldSettingsFormResponse?.code === 200) {
      setLoading(false);
      onAddFieldSettingsButtonClick();
      setFieldSettingsAddedToTrue();
      toast.success("Document Category is added successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (addDocumentFieldSettingsFormResponse?.code === 401) {
      setLoading(false);
      toast.error("Error while adding the Document Category", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const onDocumentSubAddFieldSettingsFormSubmit = async (
    addDocumentSubFieldSettingsFormData
  ) => {
    setLoading(true);

    const addDocumentSubFieldSettingsFormResponse =
      await addClientFieldSettings({
        token: token,
        userId: user_id,
        fields_name: addDocumentSubFieldSettingsFormData.fieldNameDocumentSub,
        fields_description:
          addDocumentSubFieldSettingsFormData.descriptionDocumentSub,
        fields_tab_type: "document_subtype",
        status: addDocumentSubFieldSettingsFormData.statusDocumentSub,
        is_admin: is_admin,
      });

    if (addDocumentSubFieldSettingsFormResponse?.code === 200) {
      setLoading(false);
      onAddFieldSettingsButtonClick();
      setFieldSettingsAddedToTrue();
      toast.success("Document Sub Category is added successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (addDocumentSubFieldSettingsFormResponse?.code === 401) {
      setLoading(false);
      toast.error("Error while adding the Document Sub Category", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // useEffect(() => {
  //   clientTypeLoad();
  // }, []);

  return (
    <div className="add-document-page">
      <div className="add-document-container">
        <div className="add-document-header">
          <div className="add-document-title-with-img">
            <img
              src={addFieldSettingsImg}
              height="100px"
              width="120px"
              alt="Add Field Settings"
            />
            <div className="add-document-title">Add New Field Settings</div>
          </div>
          <ButtonUI
            formButton={false}
            variant="contained"
            buttonText={<AiFillCloseCircle />}
            style={{
              background: "none",
              padding: "0px",
              minWidth: "auto",
            }}
            onClickFunc={onAddFieldSettingsButtonClick}
          ></ButtonUI>
        </div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Client Category" {...a11yProps(0)} />
              <Tab label="Client Sub Category" {...a11yProps(1)} />
              <Tab label="Document Category" {...a11yProps(2)} />
              <Tab label="Document Sub Category" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <div className="add-document-form">
              <div className="add-document-inputs">
                <div className="add-document-inputs-with-error-message">
                  <InputUI
                    name="fieldNameClient"
                    label="Enter Client Category"
                    type="text"
                    variant="outlined"
                    cssStyles={{
                      margin: "10px 0px",
                    }}
                    register={register}
                  />
                  <span className="error-message">
                    {errors["fieldNameClient"]?.message}
                  </span>
                </div>
                <div className="add-document-inputs-with-error-message select-ui">
                  <FormControl fullWidth>
                    <InputLabel id="select-label">Status</InputLabel>
                    <Controller
                      control={control}
                      name="statusClient"
                      className="custom-edit-modal"
                      defaultValue="active"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          name="statusClient"
                          sx={{ margin: "10px 0px" }}
                          labelId="select-label"
                          variant="outlined"
                          id="simple-select"
                          value={value || "active"}
                          onChange={onChange}
                          label="Status"
                          {...register("statusClient")}
                        >
                          {statusOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <span className="error-message">
                    {errors["statusClient"]?.message}
                  </span>
                </div>
              </div>
              <div className="add-document-inputs">
                <div
                  className="add-document-inputs-with-error-message"
                  style={{ width: "100%" }}
                >
                  <InputUI
                    name="descriptionClient"
                    label="Enter Description"
                    type="text"
                    variant="outlined"
                    cssStyles={{ margin: "10px 0px" }}
                    register={register}
                  />
                  <span className="error-message">
                    {errors["descriptionClient"]?.message}
                  </span>
                </div>
              </div>
              <div className="add-document-button">
                <ButtonUI
                  loading={loading}
                  variant="contained"
                  buttonText="Add Field Settings"
                  style={{
                    width: "200px",
                    height: "40px",
                    backgroundColor: "#007bff",
                    boxShadow: "none",
                    border: "1px solid #007bff",
                    fontWeight: "500",
                    borderRadius: "4px",
                    letterSpacing: "0.07rem",
                    marginTop: "20px",
                  }}
                  handleSubmit={handleSubmit}
                  onFormSubmit={onClientAddFieldSettingsFormSubmit}
                />
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div className="add-document-form">
              <div className="add-document-inputs">
                <div className="add-document-inputs-with-error-message">
                  <FormControl fullWidth>
                    <Controller
                      control={controlClientSub}
                      name="addClientType"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Autocomplete
                          id="autocomplete-client-category"
                          options={clientTypeOptions || []}
                          filterOptions={filterOptions}
                          getOptionLabel={(option) => option.label || ""}
                          sx={{ margin: "10px 0px" }}
                          value={
                            clientTypeOptions.find(
                              (option) => option.typeId === value
                            ) || null
                          }
                          onChange={(event, newValue) => {
                            // Pass the selected value to onChange to update react-hook-form state
                            onChange(newValue ? newValue.typeId : "");
                          }}
                          onBlur={onBlur}
                          isOptionEqualToValue={(option, value) =>
                            option.typeId === value
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Client Category"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <div className="add-document-inputs-with-error-message">
                  <InputUI
                    name="fieldNameClientSub"
                    label="Enter Client Sub Category"
                    type="text"
                    variant="outlined"
                    cssStyles={{
                      margin: "10px 0px",
                    }}
                    register={registerClientSub}
                  />
                  <span className="error-message">
                    {errorsClientSub.fieldNameClientSub?.message}
                  </span>
                </div>
              </div>
              <div className="add-document-inputs">
                <div className="add-document-inputs-with-error-message select-ui">
                  <FormControl fullWidth>
                    <InputLabel id="select-label">Status</InputLabel>
                    <Controller
                      control={controlClientSub}
                      name="statusClientSub"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          name="statusClientSub"
                          sx={{ margin: "10px 0px" }}
                          labelId="select-label"
                          variant="outlined"
                          id="simple-select"
                          value={value || "active"}
                          onChange={onChange}
                          label="Status"
                          {...registerClientSub("statusClientSub")}
                        >
                          {statusOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <span className="error-message">
                    {errorsClientSub.statusClientSub?.message}
                  </span>
                </div>
                <div className="add-document-inputs-with-error-message">
                  <InputUI
                    name="descriptionClientSub"
                    label="Enter Description"
                    type="text"
                    variant="outlined"
                    cssStyles={{ margin: "10px 0px" }}
                    register={registerClientSub}
                  />
                  <span className="error-message">
                    {errorsClientSub.descriptionClientSub?.message}
                  </span>
                </div>
              </div>
              <div className="add-document-button">
                <ButtonUI
                  loading={loading}
                  variant="contained"
                  buttonText="Add Field Settings"
                  style={{
                    width: "200px",
                    height: "40px",
                    backgroundColor: "#007bff",
                    boxShadow: "none",
                    border: "1px solid #007bff",
                    fontWeight: "500",
                    borderRadius: "4px",
                    letterSpacing: "0.07rem",
                    marginTop: "20px",
                  }}
                  handleSubmit={handleSubmitClientSub}
                  onFormSubmit={onClientSubAddFieldSettingsFormSubmit}
                />
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <div className="add-document-form">
              <div className="add-document-inputs">
                <div className="add-document-inputs-with-error-message">
                  <InputUI
                    name="fieldNameDocument"
                    label="Enter Document Category"
                    type="text"
                    variant="outlined"
                    cssStyles={{
                      margin: "10px 0px",
                    }}
                    register={registerDoc}
                  />
                  <span className="error-message">
                    {errorsDoc.fieldNameDocument?.message}
                  </span>
                </div>
                <div className="add-document-inputs-with-error-message select-ui">
                  <FormControl fullWidth>
                    <InputLabel id="select-label">Status</InputLabel>
                    <Controller
                      control={controlDoc}
                      name="statusDocument"
                      defaultValue="active"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          name="statusDocument"
                          sx={{ margin: "10px 0px" }}
                          labelId="select-label"
                          variant="outlined"
                          id="simple-select"
                          value={value || "active"}
                          onChange={onChange}
                          label="Status"
                          {...registerDoc("statusDocument")}
                        >
                          {statusOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <span className="error-message">
                    {errorsDoc.statusDocument?.message}
                  </span>
                </div>
              </div>
              <div className="add-document-inputs">
                <div
                  className="add-document-inputs-with-error-message"
                  style={{ width: "100%" }}
                >
                  <InputUI
                    name="descriptionDocument"
                    label="Enter Description"
                    type="text"
                    variant="outlined"
                    cssStyles={{ margin: "10px 0px" }}
                    register={registerDoc}
                  />
                  <span className="error-message">
                    {errorsDoc.descriptionDocument?.message}
                  </span>
                </div>
              </div>
              <div className="add-document-button">
                <ButtonUI
                  loading={loading}
                  variant="contained"
                  buttonText="Add Field Settings"
                  style={{
                    width: "200px",
                    height: "40px",
                    backgroundColor: "#007bff",
                    boxShadow: "none",
                    border: "1px solid #007bff",
                    fontWeight: "500",
                    borderRadius: "4px",
                    letterSpacing: "0.07rem",
                    marginTop: "20px",
                  }}
                  handleSubmit={handleSubmitDoc}
                  onFormSubmit={onDocumentAddFieldSettingsFormSubmit}
                />
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <div className="add-document-form">
              <div className="add-document-inputs">
                <div className="add-document-inputs-with-error-message">
                  <InputUI
                    name="fieldNameDocumentSub"
                    label="Enter Document Sub Category"
                    type="text"
                    variant="outlined"
                    cssStyles={{
                      margin: "10px 0px",
                    }}
                    register={registerDocSub}
                  />
                  <span className="error-message">
                    {errorsDocSub.fieldNameDocumentSub?.message}
                  </span>
                </div>
                <div className="add-document-inputs-with-error-message select-ui">
                  <FormControl fullWidth>
                    <InputLabel id="select-label">Status</InputLabel>
                    <Controller
                      control={controlDocSub}
                      name="statusDocumentSub"
                      defaultValue="active"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          name="statusDocumentSub"
                          sx={{ margin: "10px 0px" }}
                          labelId="select-label"
                          variant="outlined"
                          id="simple-select"
                          value={value || "active"}
                          onChange={onChange}
                          label="Status"
                          {...registerDocSub("statusDocumentSub")}
                        >
                          {statusOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <span className="error-message">
                    {errorsDocSub.statusDocumentSub?.message}
                  </span>
                </div>
              </div>
              <div className="add-document-inputs">
                <div
                  className="add-document-inputs-with-error-message"
                  style={{ width: "100%" }}
                >
                  <InputUI
                    name="descriptionDocumentSub"
                    label="Enter Description"
                    type="text"
                    variant="outlined"
                    cssStyles={{ margin: "10px 0px" }}
                    register={registerDocSub}
                  />
                  <span className="error-message">
                    {errorsDocSub.descriptionDocumentSub?.message}
                  </span>
                </div>
              </div>
              <div className="add-document-button">
                <ButtonUI
                  loading={loading}
                  variant="contained"
                  buttonText="Add Field Settings"
                  style={{
                    width: "200px",
                    height: "40px",
                    backgroundColor: "#007bff",
                    boxShadow: "none",
                    border: "1px solid #007bff",
                    fontWeight: "500",
                    borderRadius: "4px",
                    letterSpacing: "0.07rem",
                    marginTop: "20px",
                  }}
                  handleSubmit={handleSubmitDocSub}
                  onFormSubmit={onDocumentSubAddFieldSettingsFormSubmit}
                />
              </div>
            </div>
          </CustomTabPanel>
        </Box>
      </div>
    </div>
  );
};

export default AddFieldSettings;
